import { apiClient } from "./config/serviceConfiguration";

export default {
  getAllOrders(payload) {
    const params = new URLSearchParams();
    if (payload) {
      params.append("limit", payload.limit);
      params.append("page", payload.page);

      if (payload.user) {
        params.append("user", payload.user);
      }

      if (payload.orderState) {
        params.append("orderState", payload.orderState);
      }

      if (payload.orderPaymentState) {
        params.append("orderPaymentState", payload.orderPaymentState);
      }

      params.append("sortField", payload.sortField);
      params.append("sortOrder", payload.sortOrder);
    }
    return apiClient.get(`orders?${params.toString()}`);
  },
  getOrder(orderId) {
    return apiClient.get("orders/" + orderId);
  },
  updateOrder(orderId, payload) {
    return apiClient.put("orders/" + orderId, payload);
  },
  updateOrderStatus(orderId, payload) {
    return apiClient.patch("orders/" + orderId + "/status", payload);
  },
  updateOrderPaymentStatus(orderId, payload) {
    return apiClient.patch("orders/" + orderId + "/payment-status", payload);
  },
};
