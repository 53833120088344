import {
  removeHeaderToken,
  setHeaderToken,
} from "@/services/config/serviceConfiguration";
import authService from "@/services/authService";
import userService from "@/services/userService";

export const namespaced = true;

export const state = {
  userData: null,
};

export const mutations = {
  SET_USER_DATA(state, userData) {
    state.userData = userData;
    localStorage.setItem("userData", JSON.stringify(userData));
    setHeaderToken(userData.token);
  },
  UPDATE_USER_DATA_USER(state, user) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    userData.user = user;
    localStorage.setItem("userData", JSON.stringify(userData));
    state.userData = userData;
  },
  LOGOUT(state) {
    const email = state.userData.user.email;
    const google = window.google;

    google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    });

    google.accounts.id.revoke(email, () => {
      localStorage.removeItem("userData");
      localStorage.clear();
      location.reload();
    });
  },
  LOGOUT_INTERCEPTOR(state) {
    state.userData = null;
    localStorage.removeItem("userData");
    removeHeaderToken();
  },
};

export const actions = {
  async signup({ commit }, newUser) {
    const userDB = await authService.signup(newUser);
    commit("SET_USER_DATA", userDB.data.data);
  },
  async login({ commit }, credentials) {
    const userDB = await authService.login(credentials);
    commit("SET_USER_DATA", userDB.data.data);
  },
  async authWithGoogle({ commit }, credentialToken) {
    const credential = {
      credential: credentialToken,
    };

    const userDB = await authService.authWithGoogle(credential);
    commit("SET_USER_DATA", userDB.data.data);
  },
  logout({ commit }) {
    commit("LOGOUT");
  },
  logoutInterceptor({ commit }) {
    commit("LOGOUT_INTERCEPTOR");
  },
  setUserData({ commit }, userData) {
    commit("SET_USER_DATA", userData);
  },
  async completeUserRegistration(
    { commit, rootGetters },
    userRegistrationData
  ) {
    const appUser = rootGetters["auth/getUser"];
    const updatedUser = await userService.completeUserRegistration(
      appUser._id,
      userRegistrationData
    );
    commit("UPDATE_USER_DATA_USER", updatedUser.data.data.user);
  },
  // eslint-disable-next-line no-unused-vars
  async getImpersonateToken({ commit }, userId) {
    const response = await authService.getImpersonateToken(userId);
    return response.data.data.token;
  },
};

export const getters = {
  loggedIn(state) {
    return !!state.userData;
  },
  getUser(state) {
    if (state.userData) {
      return state.userData.user;
    }
  },
};
