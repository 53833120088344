import { apiClient } from "./config/serviceConfiguration";

export default {
  login(credentials) {
    return apiClient.post("/auth/login/admin", credentials);
  },
  authWithGoogle(credential) {
    return apiClient.post("/auth/google/admin", credential);
  },
  getImpersonateToken(userId) {
    return apiClient.get(`/auth/impersonate/${userId}`);
  },
};
