import orderService from "@/services/orderService";

export const namespaced = true;

export const state = {
  allOrders: [],
  selectedOrder: null,
  isLoadingOrders: false,
};
export const mutations = {
  SET_ALL_ORDERS(state, allOrders) {
    state.allOrders = allOrders;
  },
  SET_SELECTED_ORDER(state, order) {
    state.selectedOrder = order;
  },
  SET_IS_LOADING_ORDERS(state, value) {
    state.isLoadingOrders = value;
  },
};
export const actions = {
  async getAllOrders({ commit }, payload) {
    const response = await orderService.getAllOrders(payload);
    commit("SET_ALL_ORDERS", response.data.data.orders);
  },
  async fetchOrder({ commit }, orderId) {
    const response = await orderService.getOrder(orderId);
    commit("SET_SELECTED_ORDER", response.data.data.order);
  },
  async updateOrderState({ commit, state }, orderId) {
    const response = await orderService.updateOrderStatus(orderId, {
      orderState: state.selectedOrder.orderState,
    });
    commit("SET_SELECTED_ORDER", response.data.data.order);
  },
  async updatePaymentState({ commit, state }, orderId) {
    const response = await orderService.updateOrderPaymentStatus(orderId, {
      paymentState: state.selectedOrder.paymentState,
    });
    commit("SET_SELECTED_ORDER", response.data.data.order);
  },
  setIsLoadingOrders({ commit }, value) {
    commit("SET_IS_LOADING_ORDERS", value);
  },
};
