import productService from "@/services/productService";

export const namespaced = true;

export const state = {
  allProducts: [],
  selectedProduct: null,
  showConfirmActionPopup: false,
  searchFilter: "",
};

export const mutations = {
  SET_ALL_PRODUCTS(state, allProducts) {
    state.allProducts = allProducts;
  },
  SET_SELECTED_PRODUCT(state, selectedProduct) {
    state.selectedProduct = selectedProduct;
  },
  SET_SEARCH_FILTER(state, value) {
    state.searchFilter = value;
  },
  SET_SELECTED_PRODUCT_STOCKS(state, stocks) {
    state.selectedProductStocks = stocks;
  },
};

export const actions = {
  async getAllProducts({ commit }) {
    const response = await productService.getAllProducts();
    commit("SET_ALL_PRODUCTS", response.data.data.products);
  },
  async getProduct({ commit }, productId) {
    const response = await productService.getProduct(productId);
    commit("SET_SELECTED_PRODUCT", response.data.data.product);
  },
  async updateProduct({ commit }, payload) {
    const response = await productService.updateProduct(
      payload.id,
      payload.values
    );
    commit("SET_SELECTED_PRODUCT", response.data.data.product);
  },
  async createProduct({ commit }, product) {
    const response = await productService.createProduct(product);
    commit("SET_SELECTED_PRODUCT", response.data.data.product);
  },
  setSearchFilter({ commit }, value) {
    commit("SET_SEARCH_FILTER", value);
  },
  async uploadImage({ commit }, payload) {
    const response = await productService.uploadProductImage(
      payload.id,
      payload.formData
    );
    commit("SET_SELECTED_PRODUCT", response.data.data.product);
  },
  async deleteImage({ commit }, productId) {
    const response = await productService.deleteProductImage(productId);
    commit("SET_SELECTED_PRODUCT", response.data.data.product);
  },
  async getProductStocks({ commit }, productId) {
    const response = await productService.getProductStocks(productId);
    commit("SET_SELECTED_PRODUCT_STOCKS", response.data.data.stocks);
  },
};
