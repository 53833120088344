import { apiClient } from "./config/serviceConfiguration";

export default {
  deleteStock(stockId) {
    return apiClient.delete("stocks/" + stockId);
  },
  createStock(stock) {
    return apiClient.post("stocks", stock);
  },
  updateStock(stockId, stock) {
    return apiClient.put("stocks/" + stockId, stock);
  },
};
