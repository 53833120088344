<template>
  <div class="input-field">
    <div class="input-field-label-container">
      <label
        class="input-field-label"
        :class="home ? 'input-field-label--home' : null"
        :for="uuid"
        >{{ label }}</label
      >
    </div>

    <div class="input-container">
      <input
        v-bind="$attrs"
        :placeholder="placeholder ? placeholder : label"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :id="uuid"
        :aria-describedby="error ? `${uuid}-error` : null"
        :aria-invalid="error ? true : null"
        class="input-field-box"
        :class="error ? 'input-error' : isLoading ? 'blur' : ''"
        :type="showPassword ? 'text' : inputType"
        :disabled="isLoading"
        :autocomplete="passwordAutocomplete ? 'on' : 'off'"
      />
      <button
        v-if="inputType === 'password'"
        @click="showPassword = !showPassword"
        class="password-toggle-button"
        type="button"
      >
        <img
          v-if="showPassword"
          src="@/assets/img/icons/eye.svg"
          alt="eye"
          title="Ocultar contraseña"
        />
        <img
          v-else
          src="@/assets/img/icons/eye-off.svg"
          alt="eye off"
          title="Mostrar contraseña"
        />
      </button>
    </div>
    <p
      v-if="error"
      class="error-text"
      :id="`${uuid}-error`"
      aria-live="assertive"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import UniqueID from "@/features/uniqueID";

export default {
  data() {
    return {
      showPassword: false,
    };
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number, Date],
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    home: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    passwordAutocomplete: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uuid = UniqueID();
    return {
      uuid,
    };
  },
};
</script>

<style scoped>
.input-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 1.6rem;
}

.input-field-label-container {
  display: flex;
  gap: 9px;
  align-items: center;
}

.input-field-label {
  color: #495057;
  font-weight: 400;
  font-family: inherit;
  font-size: inherit;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-field-box {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  color: #343a40;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.0025);
  border-radius: 9px;
  height: 4rem;
  width: 100%;
  min-width: 10rem;
  padding-left: 10px;
  padding-right: 40px;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  transition: all 0.1s;
}

.input-field-box:hover {
  border: 1px solid #2eb086;
  background: #fafffd;
}

.input-field-box:focus {
  border: 1px solid #2eb086;
  outline: rgba(46, 176, 134, 0.25) 2px solid;
  background: rgba(242, 255, 249, 0.5);
}

.input-field-box::placeholder {
  opacity: 0.7;
}

.input-field-box:disabled {
  background: #eee;
  opacity: 0.75;
}

.input-error {
  border: 1px solid #ffa8a8;
}

.error-text {
  font-size: 1.6rem;
  color: #f03e3e;
}

.password-toggle-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  color: #0ca678;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.blur {
  filter: blur(1px);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spinner in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 59em) {
  .input-field-label--home {
    color: #fff;
  }
}
</style>
