import stockService from "@/services/stockService";
import productService from "@/services/productService";

export const namespaced = true;

export const state = {
  selectedProductStocks: [],
};

export const mutations = {
  SET_SELECTED_PRODUCT_STOCKS(state, stocks) {
    state.selectedProductStocks = stocks;
  },
  DELETE_STOCK(state, stockId) {
    const index = state.selectedProductStocks.findIndex(
      (stock) => stock._id === stockId
    );
    if (index !== -1) {
      state.selectedProductStocks.splice(index, 1);
    }
  },
  ADD_PRODUCT_STOCK(state, stock) {
    state.selectedProductStocks.push(stock);
  },
  UPDATE_PRODUCT_STOCK(state, stock) {
    const index = state.selectedProductStocks.findIndex(
      (s) => s._id === stock._id
    );
    if (index !== -1) {
      state.selectedProductStocks[index] = stock;
    }
  },
};

export const actions = {
  async deleteStock({ commit }, stockId) {
    await stockService.deleteStock(stockId);
    commit("DELETE_STOCK", stockId);
  },
  async getProductStocks({ commit }, productId) {
    const response = await productService.getProductStocks(productId);
    commit("SET_SELECTED_PRODUCT_STOCKS", response.data.data.stocks);
  },
  async createStock({ commit }, stock) {
    const response = await stockService.createStock(stock);
    commit("ADD_PRODUCT_STOCK", response.data.data.stock);
  },
  async updateStock({ commit }, { id, stock }) {
    const response = await stockService.updateStock(id, stock);
    commit("UPDATE_PRODUCT_STOCK", response.data.data.stock);
  },
};
