import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    path: "/panel",
    name: "panel",
    component: () => import("@/views/panel/PanelLayoutView.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/orders",
        name: "orders",
        component: () => import("@/views/panel/order/AllOrdersView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/orders/:id",
        name: "edit-order",
        props: true,
        component: () => import("@/views/panel/order/OrderView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/panel/user/AllUsersView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/users/add",
        name: "add-user",
        component: () => import("@/views/panel/user/AddUserView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/users/:id",
        name: "view-user",
        props: true,
        component: () => import("@/views/panel/user/UserView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/products",
        name: "products",
        component: () => import("@/views/panel/product/AllProductsView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/products/add",
        name: "add-product",
        component: () => import("@/views/panel/product/AddProductView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/products/:id",
        name: "edit-product",
        props: true,
        component: () => import("@/views/panel/product/ProductView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/products/:id/stocks",
        name: "edit-product-stock",
        props: true,
        component: () => import("@/views/panel/product/ProductStockView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("userData");
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next("/");
    return;
  }
  next();
});

export default router;
