import { apiClient } from "./config/serviceConfiguration";

export default {
  completeUserRegistration(userId, userRegistrationData) {
    return apiClient.patch("/users/" + userId, userRegistrationData);
  },
  updateUser(userId, values) {
    return apiClient.put("/users/" + userId, values);
  },
  getAllUserPendingOrders(userId) {
    return apiClient.get("/users/" + userId + "/pending-orders");
  },
  getAllUserOrders(userId) {
    return apiClient.get("/users/" + userId + "/orders");
  },
  getAllUserAddresses(userId) {
    return apiClient.get("/users/" + userId + "/addresses");
  },
  getAllUsers() {
    return apiClient.get("/users");
  },
  getUser(userId) {
    return apiClient.get("/users/" + userId);
  },
  createUser(user) {
    return apiClient.post("/users", user);
  },
  deleteUser(userId) {
    return apiClient.delete("users/" + userId);
  },
};
