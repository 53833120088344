<template>
  <div v-if="show" class="toast-container">
    <div class="toast" :class="getToastClass()">
      <span>{{ message }}</span>
      <button class="close-button" @click="hideToast">✕</button>
      <div class="toast-progress-bar" :style="progressStyle"></div>
    </div>
  </div>
</template>

<script>
import { ToastTypes } from "@/features/toastTypes";

export default {
  props: {
    duration: {
      type: Number,
      default: 4000,
    },
  },
  data() {
    return {
      show: false,
      progressBarWidth: 0,
      toastTimeoutId: null,
      progressBarIntervalId: null,
      toastType: ToastTypes.Success,
      message: "Success!",
    };
  },
  computed: {
    progressStyle() {
      return {
        width: `${this.progressBarWidth}%`,
      };
    },
  },
  methods: {
    fire(toastType, message) {
      this.toastType = toastType;
      this.message = message;

      this.show = true;
      this.moveProgressBar();

      if (this.toastTimeoutId) {
        clearTimeout(this.toastTimeoutId);
      }

      this.toastTimeoutId = setTimeout(() => {
        this.hideToast();
      }, this.duration);
    },
    moveProgressBar() {
      // reset progress bar
      this.progressBarWidth = 0;
      if (this.progressBarIntervalId) {
        clearInterval(this.progressBarIntervalId);
      }
      // set interval progress bar
      const timeout = this.duration / 100 - 1;
      this.progressBarIntervalId = setInterval(this.frameProgressBar, timeout);
    },
    frameProgressBar() {
      if (this.progressBarWidth >= 100) {
        clearInterval(this.progressBarIntervalId);
      } else {
        this.progressBarWidth++;
      }
    },
    hideToast() {
      this.show = false;
    },
    getToastClass() {
      switch (this.toastType) {
        case ToastTypes.Error:
          return "toast--error";
        case ToastTypes.Success:
          return "toast--success";
        case ToastTypes.Warning:
          return "toast--warning";
        default:
          return "toast--success";
      }
    },
  },
};
</script>

<style scoped>
.toast--error {
  background-color: #f03e3e;
}

.toast--success {
  background-color: #087f5b;
}

.toast--warning {
  background-color: #f08c00;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 7px;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
}

.toast-container {
  position: fixed;
  bottom: 2.4rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(32, 33, 36, 0.28);
}

.toast {
  position: relative;
  padding: 2rem 4rem 2rem 2rem;
  color: #fff;
  font-size: 16px;
}

.toast-progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  transition: width 100ms linear;
  color: #fff;
  background-color: #eee;
}

.toast--error .toast-progress-bar {
  background-color: #ffc9c9;
}

.toast--success .toast-progress-bar {
  background-color: #c3fae8;
}

.toast--warning .toast-progress-bar {
  background-color: #fff3bf;
}
</style>
