import { apiClient } from "./config/serviceConfiguration";

export default {
  getAllProducts() {
    return apiClient.get("products");
  },
  getProduct(productId) {
    return apiClient.get("products/" + productId);
  },
  updateProduct(productId, values) {
    return apiClient.put("products/" + productId, values);
  },
  createProduct(product) {
    return apiClient.post("products", product);
  },
  uploadProductImage(productId, formData) {
    return apiClient.post("products/images/" + productId, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteProductImage(productId) {
    return apiClient.delete("products/images/" + productId);
  },
  getProductStocks(productId) {
    return apiClient.get("products/" + productId + "/stocks");
  },
};
