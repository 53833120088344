<template>
  <div class="loading-screen">
    <span class="fbc-spinner"></span>
  </div>
</template>

<script></script>

<style scoped>
.loading-screen {
  margin-left: -4rem;
  padding-top: 25rem;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  opacity: 0.7;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
</style>
