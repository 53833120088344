import { createStore } from "vuex";

import * as order from "@/store/modules/order.js";
import * as auth from "@/store/modules/auth.js";
import * as user from "@/store/modules/user.js";
import * as product from "@/store/modules/product.js";
import * as stock from "@/store/modules/stock.js";

export default createStore({
  modules: {
    order,
    auth,
    user,
    product,
    stock,
  },
});
