import userService from "@/services/userService";

export const namespaced = true;

export const state = {
  allUsers: [],
  selectedUser: null,
  isLoadingUsers: false,
};

export const mutations = {
  SET_ALL_USERS(state, users) {
    state.allUsers = users;
  },
  SET_SELECTED_USER(state, user) {
    state.selectedUser = user;
  },
  SET_IS_LOADING_USERS(state, value) {
    state.isLoadingUsers = value;
  },
  ADD_CREATED_USER(state, user) {
    state.allUsers.push(user);
  },
};

export const actions = {
  async getAllUsers({ commit }) {
    const response = await userService.getAllUsers();
    commit("SET_ALL_USERS", response.data.data.users);
  },
  async getUser({ commit }, userId) {
    const response = await userService.getUser(userId);
    commit("SET_SELECTED_USER", response.data.data.user);
  },
  async updateUser({ commit }, payload) {
    const response = await userService.updateUser(payload.id, payload.values);
    commit("SET_SELECTED_USER", response.data.data.user);
  },
  setIsLoadingUsers({ commit }, value) {
    commit("SET_IS_LOADING_USERS", value);
  },
  async createUser({ commit }, user) {
    const response = await userService.createUser(user);
    commit("ADD_CREATED_USER", response.data.data.user);
  },
  async deleteUser(_, userId) {
    console.log(userId);
    await userService.deleteUser(userId);
  },
};

export const getters = {};
